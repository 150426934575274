import logo from "./logo.svg";
import "./App.css";
import MainPage from "./MainPage.js";
import AboutUs from "./AboutUs.js";
import Roadmap from "./Roadmap.js";
import Team from "./Team.js";
import FaQ from "./FaQ.js";
import Footer from "./Footer";

function App() {
  return (
    <div className="app">
      <MainPage></MainPage>
      <AboutUs></AboutUs>
      <Roadmap></Roadmap>
      <Team></Team>
      <FaQ></FaQ>
      <Footer></Footer>
    </div>
  );
}

export default App;
