import React from "react";
import "./AboutUs.css";
import solbotsAboutUs from "./assets/solbots-aboutUs.png";

function AboutUs() {
  return (
    <div id="AboutUs">
      <div className="AboutUs-bg">
        <div className="AboutUs-container">
          <h1 className="AboutUs-h1">ABOUT US</h1>
          <p className="AboutUs-p">
            SolBots is centered around being the all-in-one tool suite for the
            Solana Blockchain and NFTs in general. The goal behind SolBots is to
            provide the most value to our holders by giving them the tools and
            knowledge they need for success. From analytic tools, to discord
            bots, to on-chain sniping, to private rpc nodes, to top level spots
            in the next hottest mint.
          </p>
        </div>
        <img className="AboutUs-img" src={solbotsAboutUs} alt="" />
      </div>
    </div>
  );
}

export default AboutUs;
