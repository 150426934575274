import React from "react";
import solbotsBG1 from "./assets/solbotsBG1.png";

import solbotsLogo from "./assets/FooterLogo.png";
import MagicEden from "./assets/MEFooter.png";
import Twitter from "./assets/TwitterFooter.png";
import Discrod from "./assets/DiscordFooter.png";

import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <div className="Footer-Mid">
        <img className="logo-mid" src={solbotsLogo} alt="" />
        <h1 className="logo-bottom">SOLBOTS</h1>
      </div>
      <div className="Footer-bottom">
        <div className="copyright">
          <p>All Rights Reserved © 2022, SOLBOTS LLC.</p>
        </div>
        <div className="Social-icons">
          <a target="_blank" href="https://mobile.twitter.com/solbotsaio">
            <img src={Twitter}></img>
          </a>
          <a target="_blank" href="https://discord.gg/ePHVpwvFJF">
            <img src={Discrod} alt="" />
          </a>
          <a target="_blank" href="https://magiceden.io/marketplace/solbots">
            <img src={MagicEden} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
