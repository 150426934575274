import React from "react";
import NavbarLogo from "./assets/NavbarLogo.png";
import DownArrow from "./assets/downArrow.png";
import Twitter from "./assets/Twitter.png";
import Discord from "./assets/Discord.png";
import MagicEden from "./assets/MagicEden.png";

import "./MainPage.css";
import "./Fonts/dogicapixel.ttf";

function MainPage() {
  return (
    <div className="MainPage">
      <div className="NavBar">
        <div className="Navbar-logo">
          <a href="/">
            <img className="Navbar-logo-img" src={NavbarLogo}></img>
          </a>
          <a href="/">
            <h1 className="Navbar-logo-h1">SOLBOTS</h1>
          </a>
        </div>
        <nav className="Navbar-nav">
          <ul className="Navbar-ul">
            <li className="Navbar-ul-item">
              <a href="#AboutUs">ABOUT</a>
            </li>
            <li className="Navbar-ul-item">
              <a href="#Roadmap">CURRENT DEVELOPMENT</a>
            </li>
            <li className="Navbar-ul-item">
              <a href="#Team">TEAM</a>
            </li>
            <li className="Navbar-ul-item">
              <div className="dropdown">
                <button>UTILITY</button>
                <label>
                  <input type="checkbox" />
                  <ul>
                    <a target="_blank" href="https://legitimiser.solbots.com/">
                      <li>Legitimiser</li>
                    </a>
                    <li>Discord Bots</li>
                    <li>RPC Node</li>
                    <li className="divider"></li>
                    <a target="_blank" href="https://report.solbots.com/">
                      <li>Report(new)</li>
                    </a>
                  </ul>
                </label>
              </div>

              {/* <a href="#">
                UTILITY <img src={DownArrow}></img>{" "}
              </a> */}
            </li>

            <li className="Navbar-ul-item">
              <a target="_blank" href="https://mobile.twitter.com/solbotsaio">
                <img src={Twitter}></img>{" "}
              </a>
            </li>
            <li className="Navbar-ul-item">
              <a target="_blank" href="https://discord.gg/ePHVpwvFJF">
                <img src={Discord}></img>{" "}
              </a>
            </li>
          </ul>
          <button className="Navbar-connect-btn">CONNECT</button>
        </nav>
      </div>
      <div className="hero">
        <div className="hero-h4-intro">
          <h4>AN NFT COLLECTION OF 1,111 ROBOTS</h4>
        </div>
        <div className="hero-h1">
          <h1>SOLBOTS</h1>
        </div>
        <div className="hero-h4-outro">
          <h4>ON A MISSION TO CREATE AN ALL IN ONE PLATFORM.</h4>
        </div>
      </div>
      <a target="_blank" href="https://magiceden.io/marketplace/solbots">
        <div className="buy-me-btn">
          <div className="buy-me-btn-left">
            <div className="buy-me">
              <p>MAGIC EDEN</p>
            </div>
            <div className="buy-solbots">
              <p>BUY SOLBOTS</p>
            </div>
          </div>
          <div className="buy-me-btn-right">
            <div className="buy-me-btn-img">
              <img src={MagicEden} alt="" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default MainPage;
