import React from "react";
import "./Roadmap.css";
import NavbarLogo from "./assets/NavbarLogo.png";

import roadmapImg from "./assets/roadmap-img.png";

function Roadmap() {
  return (
    <div id="Roadmap">
      <div className="Roadmap-h1">
        <h1>CURRENT DEVELOPMENT</h1>
        <img className="Roadmap-img" src={roadmapImg} alt="" />
      </div>
      <div className="Roadmap-item-container">
        <div className="container-top">
          <h3>Private RPC Node</h3>
          <img className="container-logo" src={NavbarLogo} alt="" />
        </div>
        <div className="container-body">
          <p>Provide a private access node for SolBots Holders.</p>
        </div>
      </div>
      <div className="Roadmap-item-container2">
        <div className="container-top">
          <h3>Analytic Tool</h3>
          <img className="container-logo" src={NavbarLogo} alt="" />
        </div>
        <div className="container-body">
          <p>Provide an analytic tool to track projects.</p>
        </div>
      </div>
      <div className="Roadmap-item-container3">
        <div className="container-top">
          <h3>Legit Checker</h3>
          <img className="container-logo" src={NavbarLogo} alt="" />
        </div>
        <div className="container-body">
          <p>Provide a tool that legit checks Solana NFTs.</p>
        </div>
      </div>
      <div className="Roadmap-item-container4">
        <div className="container-top">
          <h3>Discord Bots Suite</h3>
          <img className="container-logo" src={NavbarLogo} alt="" />
        </div>
        <div className="container-body">
          <p>Discord Technology collection of 10+ bots.</p>
        </div>
      </div>
    </div>
  );
}

export default Roadmap;
