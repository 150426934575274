import React from "react";
import "./Team.css";

import FastPFP from "./assets/FastPFP.png";
import TeamTwitter from "./assets/TeamTwitter.png";

function Team() {
  return (
    <div id="Team">
      <h1 className="Team-h1">TEAM</h1>
      <div className="Team-bg">
        <img className="Team-img" src={FastPFP} alt="" />
        <div className="Team-container">
          <div className="Team-container-top">
            <div className="Team-container-left">
              <div className="Team-h2">
                <h2>FAST</h2>
              </div>
              <div className="Team-h3">
                <h3>OWNER</h3>
              </div>
            </div>
            <div className="Team-container-right">
              <a target="_blank" href="https://mobile.twitter.com/fastdagod">
                <img className="TeamTwitter-img" src={TeamTwitter} alt="" />
              </a>
            </div>
          </div>
          <div className="Team-container-bottom">
            <p className="Team-p">
              I think these bios are kind of stupid because I can sit here and
              tell you x, y, z and promise this and that but that's predictable
              right? Truth is I love crypto and I love NFTs and I wish to create
              technology that will change space. My indefatigable spirit and
              burning desire to win will never fade. See you on the other side.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
