import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./FaQ.css";

export default function SimpleAccordion() {
  return (
    <div id="FaQ">
      <h1 className="FaQ-h1">FAQ'S</h1>
      <Accordion className="Accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="Accordion-Icon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="Accordion-line"
        >
          <Typography className="Accordion-header">
            Where can I purchase a SolBots?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="Accordion-body">
            Our only currently supported marketplace is Magiceden.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="Accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="Accordion-Icon" />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="Accordion-line"
        >
          <Typography className="Accordion-header">
            What is the supply of SolBots and Baby SolBots?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="Accordion-body">
            SolBots supply is 1,111 and Baby SolBots supply is 2,222.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="Accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="Accordion-Icon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="Accordion-line"
        >
          <Typography className="Accordion-header">
            I found something called "Removed SolBots" is this the collection?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="Accordion-body">
            No. When SolBots first launched, our dev rugged the collection
            forcing us to remake and airdrop the collection.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="Accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="Accordion-Icon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="Accordion-line"
        >
          <Typography className="Accordion-header">
            How long will it take for you create these tools?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="Accordion-body">
            For updated times, I recommend joining our discord server. You can
            find a link in the bottom right hand corner of the website.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="Accordion">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="Accordion-Icon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="Accordion-line"
        >
          <Typography className="Accordion-header">
            Is SolBots an art only collection?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="Accordion-body">
            Technically yes. When we started SolBots, we started it being based
            off an art only collection. This was mainly done for legal reasons
            but upon diving deeper into web3, we decided we wanted to create
            much more.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
